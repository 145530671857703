<template>
  <v-app-bar
    app
    clipped-left
    color='#fff'
    elevation='0'
    style='margin-bottom: 64px'
  >
    <v-app-bar-nav-icon @click='toggleDrawer' />
    <v-tooltip bottom>
      <template #activator='{ on }'>
        <v-img
          contain
          max-width='110'
          max-height='50'
          class='ml-2'
          :src='logoUrl || openbioLogoUrl'
          style='cursor: pointer;'
          v-on='on'
          @click='navigateTo(Route.HOME.name)'
        />
      </template>
      {{$t('GO_TO_HOME')}}
    </v-tooltip>

    <v-spacer />

    <v-menu
      v-model='menu'
      :close-on-content-click='false'
      :nudge-width='200'
      offset-y
    >
      <template #activator='{ on, attrs }'>
        <v-btn
          icon
          v-bind='attrs'
          color='primary'
          class='mr-1'
          v-on='on'
        >
          <v-icon>
            mdi-account
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>
                mdi-account
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom>
                  <template #activator='{ on }'>
                    <span v-on='on'>
                      {{getUser.account.name && getUser.account.name.split(' ')[0] && getUser.account.name.split(' ')[0].substr(0, 20)}}
                    </span>
                  </template>
                  <span>{{getUser.account.name}}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>{{getUser.account.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list dense class='pa-0'>
          <v-list-item @click='redirectToPlatform()'>
            <v-list-item-content>
              <v-list-item-title class='color-secondary'>
                {{$t('GO_TO_SERVICES')}}
              </v-list-item-title>
              <v-list-item-subtitle>Catálogo de aplicativos Akiyama</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon color='secondary' small>
                mdi-open-in-new
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list dense class='pa-0'>
          <v-list-item v-if='isAdmin || hasMenu(Route.PROFILE.code)' @click='navigateTo(Route.PROFILE.name)'>
            <v-list-item-content>
              <v-list-item-title>
                {{$t('PROFILE')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>
                mdi-account-outline
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item v-if='isAdmin || hasMenu(Route.RELEASE_NOTES.code)' @click='navigateTo(Route.RELEASE_NOTES.name)'>
            <v-list-item-content>
              <v-list-item-title>
                {{$t('RELEASE_NOTES')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>
                mdi-note-search-outline
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item @click='signOut'>
            <v-list-item-content>
              <v-list-item-title class='red--text'>
                {{$t('SIGNOUT')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon color='error' small>
                mdi-logout
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { Route } from '@/router';
  import { mapGetters } from 'vuex';

  export default {
    name: 'AppBar',
    data: function () {
      return {
        menu: false,
        drawer: true,
        Route: Route,
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      ...mapGetters('customTheme', ['logoUrl', 'openbioLogoUrl']),
      isSuperAdmin: function () {
        return this.getUser && this.getUser.account && this.getUser.account.role && this.getUser.account.role.isSuperAdmin;
      },
      isAdmin: function () {
        return this.getUser && this.getUser.account && this.getUser.account.role && this.getUser.account.role.isAdmin;
      },
    },
    methods: {
      signOut: async function () {
        await this.$store.dispatch('auth/signOut');
        if (process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true') {
          window.location.assign(`${process.env.VUE_APP_SIGN_IN_URL}?redirectUrl=${btoa(window.location.href)}&origin=Management`);
        } else {
          this.$store.dispatch('auth/toggleSignInPageVisibility', { value: true });
        }
      },
      toggleDrawer: function () {
        this.$store.dispatch('drawer/toggleDrawerVisibility');
      },
      navigateTo: function (name) {
        if (this.$router.currentRoute.name !== name) {
          this.$router.push({ name: name });
        }
      },
      hasMenu: function (menu) {
        if (this.getUser && this.getUser.account && this.getUser.account.menuCodes) {
          return this.getUser.account.menuCodes.find((menuCode) => menuCode === menu);
        }
        return false;
      },
      redirectToPlatform: function () {
        window.open(process.env.VUE_APP_SIGN_IN_URL, '_blank');
      },
    },
  };
</script>
